import Vue         from 'vue'
import axios       from 'axios'
import VueMoment   from 'vue-moment'
import prettyBytes from 'pretty-bytes'
import Vue2Filters from 'vue2-filters'
import store       from '../store'
import Root        from '../root'
import router      from './router'
import 'bootstrap-sass/assets/javascripts/bootstrap'
import '../components'
import '../styles/app.scss'

// fontawesome icons
import { library } from '@fortawesome/fontawesome-svg-core';
import { faGoogle,
    faAmazon,
faPaypal} from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far} from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
library.add(faGoogle,
    faAmazon,
    faPaypal,
    fas,
    far);

axios.interceptors.request.use(function(config) {
    const authorization = store.getters.authorization
    if (authorization) {
        config.headers['Authorization'] = authorization
    }
    return config
})

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.use(VueMoment)
Vue.use(Vue2Filters)
Vue.filter('prettyBytes', prettyBytes)

new Vue({
    el: '#app',
    render: createElement => createElement(Root),
    router,
    store
})
